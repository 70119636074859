import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import Footer from "components/Footer/Footer.jsx";
import Sidebar from "./Sidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import routes from "./routes.js";
import AgencyContext from "context/AgencyContext";
import api from "components/API/api";
var ps;

class Admin extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      adminName: null,
      adminPicture: null,
      adminId: null,
      adminEmail: null,
      // role: "",
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    if (localStorage.Token) {
      if (this.context.agency.user) {
        return;
      } else {
        api()
          .get("/users/login/validate-token")
          .then(({ data }) => {
            // localStorage.setItem("AgencyId", agencyId);
            this.context.agencyDispatch({
              type: "SET_USER",
              payload: { user: data },
            });
            // this.context.agencyDispatch({
            //   type: "SET_PROFILE",
            //   payload: { profile: profile },
            // });
            // }
          })
          .catch((e) => {
            console.log(e);
            this.props.history.push(
              `/${this.props.match.params.slug}/auth/login`
            );
          });
      }
      //Continue
      const user = this.context.agency.user;
      const profile = this.context.agency.profile;

      // this.setState({
      //   role: profile.role,
      // });
    } else {
      // No user, lets logout
      this.props.history.push(
        `/${this.props.match.params.slug}/auth/login?redirect=${this.props.location.pathname}`
      );
    }
  }

  // access(arr1, role) {
  //   let access = arr1.find((r) => {
  //     if (r == role) return true;
  //   });
  //   return access;
  // }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    let slug = this.props.match.params.slug;
    //If the user is not logged in i.e. localstorage has no "Token" then the page will redirect to Login page
    if (!JSON.parse(localStorage.getItem("Token"))) {
      return <Redirect to={`/${slug}/auth/login`} />;
    }

    return routes.map((route, key) => {
      if (route.collapse) {
        return this.getRoutes(route.views);
      }
      console.log(route.path);
      return (
        <Route
          path={"/" + slug + route.path}
          component={route.component}
          key={key}
        />
      );
    });
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          //role={this.state.role}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Switch>{this.getRoutes(routes)}</Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
      </div>
    );
  }
}

export default Admin;
