import React, { useContext, useState, useEffect } from "react";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Button,
} from "reactstrap";
import api from "components/API/api";
import CustomCalender from "components/Calendar/CustomCalendar";
import moment from "moment";
import { startOfDay, startOfNextDay } from "utils/dateUtils";
import { AssignmentInd } from "@material-ui/icons";
import SweetAlert from "react-bootstrap-sweetalert";

function AssignedWorkers(props) {
  const [workerData, setWorkerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "hdId",
    sort: "desc",
    filter: [],
  });
  const [timer, setTimer] = useState(null);
  const [alert, setAlert] = useState(null);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (date) {
      fetchData();
    }
  }, [date]);

  const fetchData = async (filterOptions = reactTableState) => {
    try {
      setIsLoading(true);
      const { data } = await api().get("/driver-pickup/employees", {
        params: {
          ...filterOptions,
          start: startOfDay(date),
          end: startOfNextDay(date),
        },
      });
      setIsLoading(false);
      console.log("data", data);
      let users = data.employees || [];
      users = users.map((u) => ({
        ...u,
        actions: RenderActionButtons(u.employee),
        firstPickup: u.firstPickup
          ? moment(u.firstPickup.time)
              .format("MMM Do YYYY, h:mm a")
              .split(",")[1]
          : null,
        secondPickup: u?.secondPickup
          ? moment(u.secondPickup.time)
              .format("MMM Do YYYY, h:mm a")
              .split(",")[1]
          : null,
      }));
      console.log(users);
      setWorkerData(users);
      setPages(data.noPages);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderActionButtons = (workerId) => {
    return (
      <div className="actions-right">
        <Button
          onClick={() => {
            handleManualPickup(workerId);
          }}
          color="black"
          size="md"
          className="btn-link btn-icon"
        >
          <AssignmentInd style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  const handleManualPickup = async (workerId) => {
    try {
      let dataToSend = {
        date: new Date(),
        workerId: workerId,
        start: startOfDay(new Date()),
        end: startOfNextDay(new Date()),
      };
      console.log("dataToSend", dataToSend);
      await api().post("/driver-pickup/sign-in-worker", dataToSend);

      setAlert(
        <SweetAlert
          success
          title="Worker manually marked as picked up"
          onConfirm={hideAlert}
        />
      );
    } catch (error) {
      console.log(error);
      setAlert(
        <SweetAlert
          error
          title={`${error.code}, ${error.message}`}
          onConfirm={hideAlert}
        />
      );
    }
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="no-transition">
            <CardHeader>
              <div className="d-flex justify-content-between">
                <CardTitle tag="h4">All Assigned Workers</CardTitle>
              </div>
              <CustomCalender
                initialDate={date}
                onChangeDate={(date) => {
                  setDate(date);
                }}
              />
            </CardHeader>
            <CardBody>
              <ReactTable
                //Display action buttons on the left (since drivers will be in mobile view)
                columns={[
                  {
                    Header: "ID",
                    accessor: "employeeHdId",
                    maxWidth: 70,
                    filterable: false,
                  },
                  {
                    Header: "Full Name",
                    accessor: "employeeName",
                    filterable: false,
                  },
                  {
                    Header: "Phone",
                    accessor: "employeePhone",
                    maxWidth: 120,
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Shift Timing",
                    accessor: "shiftTiming",
                    maxWidth: 90,
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Note",
                    accessor: "note",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "First Pickup",
                    accessor: "firstPickup",
                    sortable: false,
                    filterable: false,
                    maxWidth: 90,
                  },
                  {
                    Header: "Second Pickup",
                    accessor: "secondPickup",
                    sortable: false,
                    filterable: false,
                    maxWidth: 90,
                  },
                  {
                    accessor: "actions",
                    maxWidth: 55,
                    sortable: false,
                    filterable: false,
                  },
                ]}
                manual
                loading={isLoading}
                pages={pages}
                data={workerData}
                onFetchData={(state, instance) => {
                  let searchParams = {
                    page: state.page + 1,
                    size: state.pageSize,
                    field: state.sorted.length ? state.sorted[0].id : "hdId",
                    sort: state.sorted.length
                      ? state.sorted[0].desc
                        ? "desc"
                        : "asc"
                      : "desc",
                    filter: state.filtered,
                  };
                  setReactTableState(searchParams);
                  clearTimeout(timer);

                  setTimer(
                    setTimeout(() => {
                      setIsLoading(true);
                      fetchData(searchParams);
                    }, 1000)
                  );
                }}
                defaultPageSize={50}
                showPaginationTop
                showPaginationBottom
                filterable
                sortable
                className="-striped -highlight primary-pagination"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
}

export default AssignedWorkers;
