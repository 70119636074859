import React, { useState } from "react";
import QrReader from "react-qr-reader";
import {
  Card,
  Button,
  CardTitle,
  CardBody,
  CardHeader,
  Row,
  Col,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import api from "components/API/api";
import { startOfDay, startOfNextDay } from "utils/dateUtils";

function QrScanner() {
  const [scanning, setScanning] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleScan = async (data) => {
    if (data) {
      setScanning(false);
      try {
        let dataToSend = {
          date: new Date(),
          code: data,
          start: startOfDay(new Date()),
          end: startOfNextDay(new Date()),
        };
        await api().post("/driver-pickup/sign-in-worker", dataToSend);

        setAlert(
          <SweetAlert
            success
            title="Worker verified successfully "
            onConfirm={hideAlert}
          />
        );
      } catch (error) {
        console.log(error);
        setAlert(
          <SweetAlert
            error
            title={error.response.data.message || error.message}
            onConfirm={hideAlert}
          />
        );
      }
    }
  };
  const handleError = (err) => {
    console.error(err);
    setAlert(<SweetAlert error title={err} onConfirm={hideAlert} />);
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div class="content">
      <Card className="no-transition">
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag="h4">Scan your QR Code here</CardTitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                size="md"
                color="primary"
                onClick={() => setScanning(!scanning)}
              >
                {scanning ? "Stop" : "Start"}
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xl="12" lg="12" md="12" sm="12">
              {scanning ? (
                <QrReader
                  delay={100}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: "70%" }}
                />
              ) : null}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {alert}
    </div>
  );
}

export default QrScanner;
