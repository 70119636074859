import React, { useState } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import { useParams } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components

function ColorNavbar(props) {
  const [imageError, setImageError] = useState(false);
  const [logoUrl, setLogoUrl] = useState(
    "http://hdgroup.ca/wp-content/themes/hdgroup/images/logo.png"
  );
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [loginData, setLoginData] = useState(
    JSON.parse(localStorage.getItem("staffingUser"))
  );
  const { slug } = useParams();

  console.log(collapseOpen);

  // React.useEffect(() => {
  //   let headroom = new Headroom(document.getElementById("navbar-main"));
  //   // initialise
  //   headroom.init();
  //   const updateNavbarColor = () => {
  //     if (
  //       document.documentElement.scrollTop > 499 ||
  //       document.body.scrollTop > 499
  //     ) {
  //       setNavbarColor("");
  //     } else if (
  //       document.documentElement.scrollTop < 500 ||
  //       document.body.scrollTop < 500
  //     ) {
  //       setNavbarColor("navbar-transparent");
  //     }
  //   };
  //   window.addEventListener("scroll", updateNavbarColor);
  //   return function cleanup() {
  //     window.removeEventListener("scroll", updateNavbarColor);
  //   };
  // });

  // React.useEffect(() => {
  //   setLogoUrl(props.logo);
  // }, [props.logo]);

  return (
    <>
      {/* {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null} */}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
        style={{ marginTop: 5, paddingTop: 20 }}
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand">
              <button
                className="navbar-toggler"
                id="navigation"
                type="button"
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  // setBodyClick(true);
                  setCollapseOpen(!collapseOpen);
                }}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>

              <img
                style={{ maxWidth: "150px", maxHeight: "50px" }}
                src={logoUrl}
                alt={props.companyName || "Logo"}
                // onError={() => {
                //   if (!imageError) {
                //     setImageError(true);
                //     setLogoUrl(
                //       "http://hdgroup.ca/wp-content/themes/hdgroup/images/logo.png"
                //     );
                //   }
                // }}
              ></img>
            </NavbarBrand>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  className="mr-2"
                  color="default"
                  //caret
                  nav
                  to={`/${slug}/auth/login`}
                  tag={Link}
                >
                  Login
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  className="mr-2"
                  color="default"
                  //caret
                  nav
                  to={`/${slug}/register`}
                  tag={Link}
                >
                  Register
                </DropdownToggle>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
