import React, { useReducer } from "react";
import { Router, Route, Switch, Redirect, HashRouter } from "react-router-dom";
import { history } from "./history";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// Messy merge //
// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import AgencyLayout from "layouts/AgencyLayout.jsx";
import Error404 from "views/pages/Error404";

// Messy merge end//

const App = (props) => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/"
          exact
          render={(props) => <Redirect to="/hd-group/login" />}
        />
        <Route path="/:slug" render={(props) => <AgencyLayout {...props} />} />
        <Route render={(props) => <Error404 {...props} />} />
      </Switch>
    </Router>
  );
};

export default App;
