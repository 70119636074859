import React, { useState, useEffect } from "react";
import {
  ExpandMore,
  AddCircleOutline,
  MoreVert,
  ChevronLeft,
  ChevronRight,
  Today,
} from "@material-ui/icons";
import ReactDatetime from "react-datetime";
import moment from "moment";

export default function CustomCalendar({ style, initialDate, onChangeDate, disablePreviousDate }) {
  const [date, setDate] = useState(moment(initialDate) || moment());

  const changeDate = function (date, change) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + change);
    return newDate;
  };

  useEffect(() => {
    if (date != moment(initialDate)) {
      let newDate = moment(date);
      newDate.set({ hour: 1 });

      if (!onChangeDate) return;
      onChangeDate(new Date(newDate));
    }
  }, [date]);

  const disablePreviousDates = (current) => {
    const today = new Date();
    return current.isAfter(today);
  };

  return (
    <div className="d-flex justify-content-between" style={{ ...style }}>
      <div
        style={{ cursor: disablePreviousDate ? "not-allowed" : "pointer" }}
        onClick={() => {
          if (disablePreviousDate) {
            if (date < new Date()) {
              return;
            }
          }
          setDate(changeDate(date, -1));
        }}
      >
        <ChevronLeft />
        Previous Day
      </div>
      <p style={{ cursor: "pointer" }}>
        <ReactDatetime
          isValidDate={disablePreviousDate ? disablePreviousDates : null}
          inputProps={{
            placeholder: "Date Picker Here",
          }}
          timeFormat={false}
          value={date}
          onChange={(date) => {
            setDate(date);
          }}
        />
        {/* <Today /> */}
      </p>
      <div style={{ cursor: "pointer" }} onClick={() => setDate(changeDate(date, 1))}>
        Next Day
        <ChevronRight />
      </div>
    </div>
  );
}
