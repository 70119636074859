import React, { useContext, useState, useEffect } from "react";
import Background from "assets/img/sections/forest-bg.jpg";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Spinner as ReactSpinner } from "reactstrap";
import { Redirect, useLocation, useParams } from "react-router-dom";
// import './style.css'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import api from "components/API/api";
import AgencyContext from "context/AgencyContext";
import moment from "moment";

export default function Login(props) {
  // static contextType = AgencyContext;
  const context = useContext(AgencyContext);
  const [Login, setLogin] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [Dob, setDob] = useState("");
  const [displayFirstPasswordModal, setDisplayFirstPasswordModal] =
    useState(false);
  const [displayResetPasswordModal, setDisplayResetPasswordModal] =
    useState(false);
  const [displayInviteCodeModal, setDisplayInviteCodeModal] = useState(false);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [redirect, setRedirect] = useState(null);
  const [marginTop, setMarginTop] = useState("calc(50vh - 230px)");

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let redirect = params.get("redirect");

  const splitRedirect = redirect?.split("/");

  /****just to make sure the slug is hd-group for redirection */
  if (splitRedirect?.length && splitRedirect[1] !== "hd-group") {
    redirect = null;
  }

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("Token"));
    if (token) {
      api()
        .get("/users/login/validate-token")
        .then(({ data }) => {
          processLoginData({
            user: data,
            token,
          });
        })
        .catch((e) => {
          console.log(e);
          context.agencyDispatch({ type: "LOGOUT" });
        });
    }
  }, []);

  const submitLogin = async () => {
    setLoading(true);
    // console.log(Password)
    try {
      const { data } = await api().post("/users/login/driver", {
        email: Login,
        password: Password,
        slug: props.match.params.slug,
        source: "web", // tracking purposes
      });
      console.log(data)
      if (data.action === "reset") {
        resetAlert();
      } else {
        processLoginData(data);
      }
    } catch (error) {
      console.log(error);
      noSuccessAlert("Invalid email or password, please try again.");
    }
    setLoading(false);
  };

  const sendResetLink = async () => {
    // setState({ loading: true });
    if (!Login || Login.trim() === "") {
      noSuccessAlert("Please enter your email.");
      return;
    }
    setLoading(true);
    try {
      const { data } = await api().post("/users/request/reset-password", {
        email: Login,
        slug: props.match.params.slug,
      });
      console.log(data);
      resetAlert();
    } catch (error) {
      console.log(error);
      noSuccessAlert("Your account cannot be reset.");
    }
    setLoading(false);
  };

  const setFirstPassword = async () => {
    setLoading(true);
    if (Password !== ConfirmPassword) {
      noSuccessAlert("The two password fields must be identical.");
      setLoading(false);
      return;
    }
    if (Password.length < 7) {
      noSuccessAlert("The password must contain at least 7 characters.");
      setLoading(false);
      return;
    }
    try {
      const { data } = await api().post("/users/reset/password/dob", {
        email: Login,
        password: Password,
        dob: Dob,
      });
      processLoginData(data);
    } catch (error) {
      console.log(error);
      noSuccessAlert("Password could not be set.");
    }
    setLoading(false);
  };

  const processLoginData = async (data) => {
    let profile = data.user.profile.find(
      (p) => p.role === "Driver" && p.agency.slug === props.match.params.slug
    );
    if (profile) {
      const agency = profile.agency;

      localStorage.setItem("Token", JSON.stringify(data.token));

      try {
        context.agencyDispatch({
          type: "SET_USER",
          payload: { user: data.user },
        });
        context.agencyDispatch({
          type: "SET_PROFILE",
          payload: { profile: profile },
        });
        props.history.push(`/hd-group/workers`);
      } catch (e) {
        console.log(e);
      }
    } else {
      noSuccessAlert("Invalid email or password, please try again.");
    }
  };

  //What : On key press enter verify credentials
  //Input : press enter after completing form
  //Output : buttonClick is executed if the key pressed is "Enter"

  const keyPress = (e) => {
    if (e.key === "Enter") {
      submitLogin();
    }
  };

  //What : Sent an Alert if the sign in is not successful
  //Input : No input required.
  //Output : Alert

  const noSuccessAlert = (input) => {
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Error!"
        onConfirm={() => {
          setPassword(null);
          setConfirmPassword(null);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
      >
        {input}
      </ReactBSAlert>
    );
  };

  const successAlert = (input) => {
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
      >
        {input}
      </ReactBSAlert>
    );
  };

  const resetAlert = () => {
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Reset your password"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
      >
        A password reset link has been sent to your email. Please use the link
        to create a new password.
      </ReactBSAlert>
    );
  };

  //What : Changes the marginTop property for the Login Component to ensure a dynamically centered component
  //Input : No input required.
  //Output : Changes marginTop property in state

  const windowCheck = () => {
    if (window.innerHeight > 550) {
      setMarginTop("calc(50vh - 230px)");
    } else {
      setMarginTop("50px");
    }
  };

  const renderLoginForm = () => {
    return (
      <Card className="card-login no-transition">
        <CardHeader style={{ paddingTop: "10px" }}>
          <CardHeader>
            <h3 className="header text-center">LOGIN</h3>
          </CardHeader>
        </CardHeader>
        <CardBody>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              onChange={(e) => setLogin(e.target.value)}
              value={Login}
              style={{
                fontFamily: "Trebuchet MS, Helvetica, sans-serif",
              }}
              placeholder="Email"
              type="email"
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-key-25" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Password"
              type="password"
              autoComplete="off"
              value={Password}
              onKeyPress={(e) => keyPress(e)}
              style={{
                fontFamily: "Trebuchet MS, Helvetica, sans-serif",
              }}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
          <br />
        </CardBody>
        <CardFooter>
          <Button
            block
            className="btn-round mb-3"
            // color="warning"
            // href="#pablo"
            onClick={submitLogin}
            // style={{backgroundColor : '#004A8C'}}
            style={{ backgroundColor: "rgb(30,30,30)" }}
          >
            Login
          </Button>
          <Button
            block
            className="btn-round mb-3"
            // color="warning"
            // href="#pablo"
            onClick={() => {
              setDisplayResetPasswordModal(true);
            }}
            // style={{backgroundColor : '#004A8C'}}
            style={{ backgroundColor: "rgb(30,30,30)" }}
          >
            Forgot Password ?
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const renderFirstPasswordForm = () => {
    return (
      <Card className="card-login no-transition">
        <CardHeader style={{ height: "80px", paddingTop: "10px" }}>
          <CardHeader>
            <h3 className="header text-center">Create a new password</h3>
          </CardHeader>
        </CardHeader>
        <CardBody>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              onChange={(e) => setPassword(e.target.value)}
              placeholder="New password"
              value={Password}
              style={{
                fontFamily: "Trebuchet MS, Helvetica, sans-serif",
              }}
              type="password"
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-key-25" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Repeat your password"
              type="password"
              autoComplete="off"
              value={ConfirmPassword}
              onKeyPress={(e) => keyPress(e)}
              style={{
                fontFamily: "Trebuchet MS, Helvetica, sans-serif",
              }}
              onChange={(e) =>
                // setState({ ConfirmPassword: e.target.value })
                setConfirmPassword(e.target.value)
              }
            />
          </InputGroup>
          <br />
        </CardBody>
        <CardFooter>
          <Button
            block
            className="btn-round mb-3"
            // color="warning"
            // href="#pablo"
            onClick={setFirstPassword}
            // style={{backgroundColor : '#004A8C'}}
            style={{ backgroundColor: "rgb(30,30,30)" }}
          >
            Login
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const resetPasswordModal = () => {
    return (
      <Card className="card-login no-transition">
        <CardHeader style={{ height: "80px", paddingTop: "10px" }}>
          <CardHeader>
            <h3 className="header text-center">Forgot Password</h3>
          </CardHeader>
        </CardHeader>
        <CardBody>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              onChange={(e) => setLogin(e.target.value)}
              value={Login}
              style={{
                fontFamily: "Trebuchet MS, Helvetica, sans-serif",
              }}
              placeholder="Email"
              type="email"
            />
          </InputGroup>
          <br />
        </CardBody>
        <CardFooter>
          <Button
            block
            className="btn-round mb-3"
            // color="warning"
            // href="#pablo"
            onClick={sendResetLink}
            // style={{backgroundColor : '#004A8C'}}
            style={{ backgroundColor: "rgb(30,30,30)" }}
          >
            Send Reset Link
          </Button>
          <Button
            block
            className="btn-round mb-3"
            // color="warning"
            // href="#pablo"
            onClick={() => {
              setDisplayResetPasswordModal(false);
            }}
            // style={{backgroundColor : '#004A8C'}}
            style={{ backgroundColor: "rgb(30,30,30)" }}
          >
            Login
          </Button>
        </CardFooter>
      </Card>
    );
  };

  // if (redirect) {
  //   return <Redirect to={redirect} />;
  // }

  window.addEventListener("resize", windowCheck);

  return (
    <div className="login-page">
      {alert}

      <div
        className="full-page-background"
        style={{
          backgroundColor: "rgb(221,221,221)",
          backgroundImage: `url(${Background})`,
          zIndex: 2,
        }}
      />

      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6" sm="8">
            <Form
              style={{
                marginTop: marginTop,
                fontFamily: "Trebuchet MS, Helvetica, sans-serif",
              }}
              action=""
              className="form"
              method=""
            >
              <div style={{ position: "fixed", top: "50%", left: "50%" }}>
                {loading && (
                  <ReactSpinner
                    className="text-center"
                    type="border"
                    color="secondary"
                    size="6"
                  />
                )}
              </div>
              {/* {!loading && !displayFirstPasswordModal && renderLoginForm()}
              {!loading &&
                displayFirstPasswordModal &&
                renderFirstPasswordForm()} */}
              {!loading && displayFirstPasswordModal
                ? renderFirstPasswordForm()
                : displayResetPasswordModal
                ? resetPasswordModal()
                : renderLoginForm()}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
