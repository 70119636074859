/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import AllWorkers from "../views/AllWorkers";
import QrScanner from "../views/QrScanner";

export const routes = [
  {
    collapse: false,
    name: "All Workers",
    icon: "nc-icon nc-single-02",
    state: "workersCollapse",
    path: "/workers",
    component: AllWorkers,
  },
  {
    collapse: false,
    name: "QR Scanner",
    icon: "nc-icon nc-camera-compact",
    state: "scannerCollapse",
    path: "/qr-scanner",
    component: QrScanner,
  },


];

export default routes;
