/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { useParams } from "react-router-dom";
import logo from "assets/img/logo-long.png";
import AgencyContext from "context/AgencyContext";
import { baseURL } from "components/API/api";
import { useHistory } from "react-router-dom";
import api from "components/API/api";
function Sidebar(props) {
  let agencyContext = useContext(AgencyContext);

  let history = useHistory();
  let logoUrl =
    agencyContext.agency.agency &&
    agencyContext.agency.agency._id &&
    `${baseURL}/agency/${agencyContext.agency.agency._id}/logo`;

  useEffect(() => {
    let ps;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sideBarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);
  console.log(agencyContext.agency);
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {
      adminPicture: null,
      adminEmail: null,
    };
    routes.map((route, key) => {
      if (route.collapse) {
        initialState = {
          [route.state]: getCollapseInitialState(route.views),
          ...getCollapseStates(route.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  const [state, setState] = useState(getCollapseStates(props.routes));

  const { slug } = useParams();
  const sideBarRef = useRef();

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((route, key) => {
      if (route.redirect) {
        return null;
      }
      if (route.collapse) {
        var st = {};
        st[route["state"]] = !state[route.state];
        return (
          <li
            className={getCollapseInitialState(route.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[route.state]}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {route.icon !== undefined ? (
                <>
                  <i className={route.icon} />
                  <p>
                    {route.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{route.mini}</span>
                  <span className="sidebar-normal">
                    {route.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={state[route.state]}>
              <ul className="nav">{createLinks(route.views)}</ul>
            </Collapse>
          </li>
        );
      }

      //Check if the component is accessible to the admin

      return route.hidden == true ? null : (
        <li className={activeRoute("/" + slug + route.path)} key={key}>
          <NavLink to={"/" + slug + route.path} activeClassName="">
            {route.icon !== undefined ? (
              <>
                <i className={route.icon} />
                <p>{route.name}</p>
              </>
            ) : route.materialIcon !== undefined ? (
              <>
                <span className="sidebar-mini-icon">{route.materialIcon}</span>
                <span className="sidebar-normal">{route.name}</span>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{route.mini}</span>
                <span className="sidebar-normal">{route.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  //console.log(createLinks(props.routes));
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo" style={{ textAlign: "center" }}>
        <div className="logo" className="logo-mini">
          <img src={logo} alt="hd-group-logo" />
        </div>
        {/* <div className="info d-flex justify-content-center">
          <span style={{ color: "#2ba9cd", fontWeight: "bold" }}>
            {agencyContext.agency &&
              agencyContext.agency.agency &&
              agencyContext.agency.agency.name}
          </span>
        </div> */}
      </div>

      <div className="sidebar-wrapper" ref={sideBarRef}>
        <div className="user">
          {/* <div className="photo">
            <img uri={state.adminPicture} alt="" />
          </div> */}
          <div className="info">
            <a
            //  href="#pablo"
            //  data-toggle="collapse"
            ///aria-expanded={state.openAvatar}
            //  onClick={() =>
            //    setState({ openAvatar: !state.openAvatar })
            // }
            >
              <span style={{ color: "#2ba9cd" }}>
                {agencyContext.agency &&
                  agencyContext.agency.user &&
                  agencyContext.agency.user.email}
              </span>
            </a>
          </div>
        </div>
        <Nav>
          {createLinks(props.routes)}
          <li>
            <a
              data-toggle="collapse"
              onClick={(e) => {
                api()
                  .post("/users/logout")
                  .then(() => {
                    console.log("logged out from server");
                    return true;
                  })
                  .catch((e) => {
                    console.log(e);
                  });
                agencyContext.agencyDispatch({ type: "LOGOUT" });
                history.push(`/${slug}/auth/login`);
              }}
            >
              <i className="nc-icon nc-minimal-left" />
              <p>Logout</p>
            </a>
          </li>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
