import React, { useEffect, useReducer } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useParams,
} from "react-router-dom";
import AuthLayout from "./AuthLayout.jsx";
import UserLayout from "./UserDashLayout.jsx";
import AgencyContext from "context/AgencyContext";
import agencyReducer from "reducers/agencyReducer";
import CreatePassword from "views/pages/CreatePassword.jsx";
import Error404 from "views/pages/Error404.jsx";
import api from "components/API/api";

const initialState = () => {
  // const user = localStorage.getItem("user");
  // const profile = localStorage.getItem("profile");
  // const agency = localStorage.getItem("agency");

  // return {
  //   user: user && JSON.parse(user),
  //   agency: agency && JSON.parse(agency),
  //   profile: profile && JSON.parse(profile),
  // };
  return {
    user: null,
    agency: null,
    profile: null,
    images: [],
  };
};

function Admin(props) {
  const [agency, agencyDispatch] = useReducer(agencyReducer, initialState());
  let { slug } = useParams();
  useEffect(() => {
    loadAgencyFromSlug(slug);
  }, []);

  const loadAgencyFromSlug = (slug) => {
    api()
      .get(`/agency/get/${slug}`)
      .then((result) => {
        agencyDispatch({
          type: "SET_AGENCY",
          payload: { agency: result.data },
        });
      })
      .catch((e) => {
        props.history.push(`/${slug}/error`);
        console.log(e);
      });
  };
  return (
    <AgencyContext.Provider value={{ agency, agencyDispatch }}>
          <Switch>
            <Route
              path="/:slug/auth"
              render={(props) => <AuthLayout {...props} />}
            />
            <Route
              path="/:slug/create-password"
              render={(props) => <CreatePassword {...props} />}
            />
            {/* <Route path="/:slug/admin/create-shift" render={(props) => <CreateShifts {...props} />} /> */}
            <Route
              path="/:slug/error"
              render={(props) => <Error404 {...props} />}
            />
            <Route
              path="/:slug"
              render={(props) => <UserLayout {...props} />}
            />
            <Route render={(props) => <Error404 {...props} />} />
          </Switch>
    </AgencyContext.Provider>
  );
}

export default Admin;
