/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Login from "views/pages/Login.jsx";

import AuthNavbar from "components/Navbars/ColorNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import AgencyContext from "context/AgencyContext";

import { baseURL } from "components/API/api";
var ps;

class Pages extends React.Component {
  static contextType = AgencyContext;
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    let logo =
      this.context.agency &&
      this.context.agency.agency &&
      this.context.agency.agency._id
        ? `${baseURL}/agency/${this.context.agency.agency._id}/logo`
        : null;
    return (
      <>
        {/* <AuthNavbar
          logo={logo}
          companyName={
            this.context.agency.agency && this.context.agency.agency.name
          }
        /> */}
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className="full-page section-image">
            <Switch>
              <Route
                exact
                path="/:slug/auth/login"
                render={(props) => <Login {...props} />}
              />
              <Redirect to="/:slug/auth/login" />
              {/* {this.getRoutes(routes)} */}
            </Switch>
            {/* <Footer fluid /> */}
          </div>
        </div>
      </>
    );
  }
}

export default Pages;
